.WalletBackground {

    /* width: 100%;
    height: 100vh;
    background: url("../../assets/LoginBackground.png") no-repeat;
    background-size: cover;
    image-rendering: pixelated;
    display: flex;
    justify-content: center;
    align-items: center; */
}

.WalletPannel {


    position: fixed;
    width: 100%;
    height: 100vh;
    background: url("../../assets/LoginBackground.png") no-repeat;
    background-size: cover;
    image-rendering: pixelated;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 100;

}

.WalletPannelConnect {

    font-weight: bold;
    max-width: 500px;
    padding: 20px;
    border: 4px solid #000;
    border-radius: 4px;
    background: #fff;
    padding-top: 40px;
    position: fixed;
    z-index: 100;
}

.WalletAddress {
    width: 90%;
    text-align: center;
    margin: 0px auto;
}

.WalletAddressConnect {
    width: 100%;
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    margin: 0px auto;
    padding-bottom: 20px;

}

.NetworkList {
    margin: 0px;
    padding: 0px;
}

.NetworkList li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.NetworkName {}

.NetworkItem {}

.NetworkButton {
    border-style: solid;
    border-image: url("../../assets/ui/Button.png")  12 fill;
    image-rendering: pixelated;
    border-width: 8px;
    padding: 4px;
    text-align: center;
    color: #332018;
    cursor: pointer;
}

.NetworkInfo {
    color: #000;
    font-weight: normal;
    display: block;
    width: 100%;
    text-align: center;
}

.NetworkTitle,
.WalletTitle {
    text-align: left;
    width: 100%;
}

.WalletBtn {
    border-style: solid;
    border-image: url("../../assets/ui/Button.png")  12 fill;
    image-rendering: pixelated;
    border-width: 8px;
    padding:10px 4px;
    text-align: center;
    color: #332018;
    cursor: pointer;
    width: 200px;
    margin: 0px auto;
}


.NetworkList li:hover,
.WalletBtn:hover {
    /* border-image: url("../../assets/ui/ButtonHover.png")  12 fill; */
}

.GameScore {
    align-items: center;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.GameScore li {
    align-items: center;
    display: flex;
}

.BurnWalletTab {
    width: 200px;
    display: flex;
    font-weight: bold;
    border: 4px solid #000;
    border-radius: 4px;
    cursor: pointer;
    margin: 0px auto;
}

.BurnerWallerTip {
    width: calc(100% - 20);
    background: #FFB6C5;
    color: #FF3D66;
    padding: 10px;
    margin: 0px auto;
    margin-top: 20px;


    box-shadow: -2px 0 0 0 #FF3D66,
    2px 0 0 0 #FF3D66,
    0 -2px 0 0 #FF3D66,
    0 2px 0 0 #FF3D66;
}

.BurnWalletTabSelect {
    width: 100px;
    height: 36px;
    line-height: 36px;
    background: #000;
    color: #fff;
    text-align: center;
}

.BurnWalletTabNormal {
    width: 100px;
    height: 36px;
    line-height: 36px;
    color: #000;
    text-align: center;
}

.BurnWalletCreate {
    border-style: solid;
    border-image: url("../../assets/ui/Button.png")  12 fill;
    image-rendering: pixelated;
    border-width: 8px;
    padding: 4px;
    text-align: center;
    color: #332018;
    cursor: pointer;
    width: calc(100% - 20px);
    margin: 0px auto;
    margin-top: 30px;
}

.BurnWalletCreate:hover{
    border-image: url("../../assets/ui/ButtonHover.png")  12 fill;
}




.BurnWalletNew {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
}

.BurnWalletInput {
    width: calc(100% - 8px);
    padding: 10px 4px;
    margin: 0px auto;
    display: block;
    box-shadow: -2px 0 0 0 #4C3024,
        2px 0 0 0 #4C3024,
        0 -2px 0 0 #4C3024,
        0 2px 0 0 #4C3024;
    font-family: fac;
    border: none;
    background: #FFF0D2;
}

.BurnWalletNewInfo {
    width: 64%;

}

.AddBurnWalletBtn {
    border-style: solid;
    border-image: url("../../assets/ui/Button.png")  12 fill;
    image-rendering: pixelated;
    border-width: 8px;
    padding: 10px 4px;
    text-align: center;
    color: #332018;
    cursor: pointer;
    width: calc(100% - 20px);
    margin: 0px auto;
    margin-top: 10px;
}

.BurnWalletNewInfo span {
    font-weight: bold;
}

.BurnWalletGenerate {
  

    border: 3px solid #000;






    border-style: solid;
    border-image: url("../../assets/ui/Button.png")  12 fill;
    image-rendering: pixelated;
    border-width: 8px;
    padding: 4px;
    text-align: center;
    color: #332018;
    cursor: pointer;

 



}

.changeBurnWallet {
    cursor: pointer;
    font-weight: bold;
    border: 3px solid #000;
    border-radius: 4px;
    padding: 4px;
}

.BurnWalletLoading {
    width: 100%;
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
    font-weight: bold;
}

.BurnWalletAddress {
    cursor: pointer;
    text-align: right;
    display: flex;
    align-items: center;
}

.myBurnWalletMenu {

    width: 150px;
    border: 3px solid #000;
    border-radius: 3px;
    background: #fff;
}

.myBurnWalletMenuItem {
    width: 100%;
    text-align: center;
    padding: 10px 0px;
    cursor: pointer;
}

.myBurnWalletMenuItem:hover {
    color: #fff;
    background: #000;
}

.CreatePlayerButton{
    border: 3px solid #000;
    border-style: solid;
    border-image: url("../../assets/ui/Button.png")  12 fill;
    image-rendering: pixelated;
    border-width: 8px;
    padding:10px 4px;
    text-align: center;
    color: #332018;
    cursor: pointer;
}

.GameScoreContainer {
    width: 100%;
}



.CharacterTitle{
    font-size: 16px;
    width: 100%;
    margin: 10px auto;
}
.CharacterImages{
    flex-wrap: wrap;
    display: flex;
}
.CharacterImages li{
    cursor: pointer;
}

.CharacterImages_Sel{
    width: 48px;
    height: 48px;
    border-image: url("../../assets/ui/BuildBarItemSelect.png") 12 12 12 12 fill;
    border-style: solid;
    border-width: 20px;
    image-rendering: pixelated;
}
.CharacterImages_Nor{
    width: 48px;
    height: 48px;
    border-color: rgba(255, 0, 0, 0);
    border-style: solid;
    border-width: 20px;
    image-rendering: pixelated;
}
.CharacterHead{
    display: block;
    width: 100%;
}
.LoginLogo{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0px auto;
    padding-bottom: 20px;
}
.LoginLogo p{
    font-size: 32px;
    margin-top: 20px;
}