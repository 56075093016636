.MainTown{
    width: 624px;
    height: 544px;
    position: relative;
}
.MainTownFountain{
    position: absolute;
    width: 32px;
    height: 32px;
    bottom:170px;
    right: 170px;
}
.MainTownKing{
    cursor: pointer;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom:100px;
    left: 290px;
}
.MainTownGoblin1{
    cursor: pointer;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top:170px;
    right: 80px;
}
.MainTownGoblin2{
    cursor: pointer;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top:225px;
    right: 36px;
}
.MainTownGoblin3{
    cursor: pointer;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom:240px;
    left: 35px;
}
.MainTown_AnimalWolf{
    position: absolute;
    width: 32px;
    height: 32px;
    top:100px;
    left: 300px;
}

.MainTown_AnimalBear{
    position: absolute;
    width: 32px;
    height: 32px;
    top:130px;
    right: 80px;
}

.MainTown_AnimalBoar{
    position: absolute;
    width: 32px;
    height: 32px;
    top:180px;
    left: 140px;
}

.MainTown_AnimalBunny{
    position: absolute;
    width: 32px;
    height: 32px;
    top:280px;
    right: 60px;
}

.MainTown_AnimalDeer1{
    position: absolute;
    width: 32px;
    height: 32px;
    bottom:100px;
    left: 100px;
}

.MainTown_AnimalDeer2{
    position: absolute;
    width: 32px;
    height: 32px;
    bottom:100px;
    left: 78px;
}
.MainTown_AnimalFox{
    position: absolute;
    width: 32px;
    height: 32px;
    top:200px;
    left: 36px;
}

.MapLandImg{
    width: 100%;
}


.Cloud1{
    opacity: 0;
    position:absolute ;
    top: 15px;
    animation:CloudAnimate linear 12s infinite;
    
}
.Cloud2{
    opacity: 0;
    position:absolute ;
    top: 20px;
    animation:CloudAnimate linear 12s infinite;
    animation-delay: 6s;
}
.Cloud3{
    opacity: 0;
    position:absolute ;
    top: 60px;
    animation:CloudAnimate linear 12s infinite;
    animation-delay: 8s;
}
.Cloud4{
    opacity: 0;
    position:absolute ;
    top: 0px;
    animation:CloudAnimate linear 12s infinite;
    animation-delay: 2s;
}


@keyframes CloudAnimate {
    0% {
      opacity: 0;
      right:0px;
    }
    50% {
      opacity: 1;
      right:250px;
    }
    100% {
      opacity: 0;
      right: 500px;
    }
  }