.World{
    width: 100%;
    height: 100vh;
    margin: 0px auto;
}

.Map{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.react-transform-wrapper ,.react-transform-component{
    width: 100% !important;
    height: 100% !important;
}


.GameLogo{
    position: absolute;
    z-index: 100;
    left: 50%;
    transform:translate(-50%, 0%);
    top: 50px;
}


.LeftBar {
    position: fixed;
    top: 10px;
    z-index: 100;
}
.LeftBar li{
    cursor: pointer;
    display: flex;
    align-items: center;
    border-style: solid;
    border-image: url("../../assets/ui/PlayerBg.png")  6 6 6 6 fill;
    image-rendering: pixelated;
    border-width: 12px;
    font-size: 14px;
    margin-left: 10px;
    flex-direction: column;
    width: 70px;
    margin-top: 10px;
}
.LeftBar li img{
    width: 32px;
    height: 32px;
}
.LeftBar li p{
    font-size: 12px;
}
/* .LeftBar li:hover{
    width: 128px;
} */

.BattleModal{
    z-index: 101;
}
.DialogModal{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}
.DialogModalContent{
    margin-left: 10px;
    overflow: scroll;

}


@keyframes grow {
    0% { max-height: var(--lineHeight); }
    100% { max-height: calc(var(--lineHeight) * var(--lines)); }
  }
  
  @keyframes carriageReturn {
    0% { top: 0; }
    100% { top: calc(var(--lineHeight) * var(--lines)); }
  }
  
  @keyframes type {
    0% { width: 100%; }
    100% { width: 0%; }
  }
  
  @keyframes caret {
    0% { color: var(--bgColor); }
    100% { color: black; }
  }
  
  .typewriter {
    --bgColor: #FED37F;
    --lines: 500;
    --lineHeight: 1.5rem;
    --timePerLine: 1s;
    --widthCh: 22;
    --width: calc(var(--widthCh) * 1ch);
    /* do not touch the time property!!! */
    --time: calc(var(--lines) * var(--timePerLine));
    animation: grow var(--time) steps(var(--lines));
    animation-fill-mode: forwards;
    background: var(--bgColor);  
    line-height: var(--lineHeight);
    max-height: var(--lineHeight);
    overflow: hidden;
    position: relative;
    width: 100%;
    /* word-break: break-all; */

  }
  
  .typewriter::before {
    content: "";
    animation: 
      type var(--timePerLine) linear infinite, 
      carriageReturn var(--time) steps(var(--lines)) var(--lines),
      caret 0.5s steps(2) infinite;
    background: var(--bgColor);
    border-left: 2px solid;
    bottom: 0;
    height: 2rem;
    position: absolute;
    right: 0;
    width: 100%;
  }
  
  .typewriter.no-caret::before {
    border-left-width: 0;
  }
  

  .typewriter.monospace {
    font-family: monospace, monospace;
  }
  
  .typewriter.monospace::before {
    animation: 
      type var(--timePerLine) steps(var(--widthCh)) infinite, 
      carriageReturn var(--time) steps(var(--lines)) var(--lines),
      caret 0.5s steps(2) infinite;
  }
  

  