@font-face {
    font-family: fac;
    src: url("./assets/font/atfantrg.ttf");
}
*{
    font-family: fac;
    /* word-spacing: 6px;
    letter-spacing: 1px; */
}
body{
    background: #1983FD;
    margin: 0px;
}
ul,li,p{
    margin: 0px;
    padding: 0px;
    list-style: none;
    margin-block-start: 0px;
    padding-inline-start: 0px;
}
.Toastify .Toastify__toast{


    border-radius: 0px;
    box-shadow: -2px 0 0 0 #000,
    2px 0 0 0 #000,
    0 -2px 0 0 #000,
    0 2px 0 0 #000;
}

.ant-message .ant-message-notice-wrapper .ant-message-notice-content {
    border-radius: 0px;
    box-shadow: -2px 0 0 0 #000,
    2px 0 0 0 #000,
    0 -2px 0 0 #000,
    0 2px 0 0 #000;
}

img{
    image-rendering: pixelated;
}

.ant-modal .ant-modal-content{
    border-style: solid;
    border-image: url("./assets/ui/Pannel.png")  6 6 6 6 fill;
    image-rendering: pixelated;
    border-width: 12px;
    /* max-width: 500px;
    width: 90%; */
    padding-bottom: 20px;

}
.ant-modal .ant-modal-header {
    background: none;
}

.ant-drawer .ant-drawer-wrapper-body{
    border-style: solid;
    border-image: url("./assets/ui/Pannel.png")  6 6 6 6 fill;
    image-rendering: pixelated;
    border-width: 12px;
    width: 60%;
    margin: 0px auto;


}
.ant-drawer .ant-drawer-body{
    padding: 0px;

}
.ant-drawer .ant-drawer-content {
    background: none;
    padding-bottom: 30px;
}
.ant-drawer-bottom>.ant-drawer-content-wrapper {
    box-shadow:none;
}

.ant-popover .ant-popover-inner{
    border-radius: 0px;
}
.flipped {
    transform: scaleX(-1);
  }
  .LandChat{
    display: flex;
    align-items: center;
    font-size: 10px;
    border-style: solid;
    border-image: url("./assets/ui/Chat.png")  2  fill;
    image-rendering: pixelated;
    border-width: 3px;
    padding: 2px;
}