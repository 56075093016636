.Craft {
    padding-top: 20px;
}

.DrawContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.DrawArea {
    position: relative;
    width: 320px;
    height: 320px;

}

.Draw {
    z-index: 10;
    width: 320px;
    height: 320px;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
}

.Draw li {

    width: 20px;
    height: 20px;

}

.DrawTools {
    display: flex;
}

.DrawGrid {
    z-index: 1;
    width: 320px;
    height: 320px;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    border: 1px solid #000;
}

.DrawGrid li {
    border: 1px solid #000;
    width: 18px;
    height: 18px;
    background: #fff;
}

.ToolItemNormal {
    cursor: pointer;
    width: 100px;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ToolItemSelect {
    cursor: pointer;
    width: 100px;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -2px 0 0 0 #4C3024,
        2px 0 0 0 #4C3024,
        0 -2px 0 0 #4C3024,
        0 2px 0 0 #4C3024;
}

.DrawPreview {
    width: 128px;
    height: 128px;
    box-shadow: -2px 0 0 0 #4C3024,
        2px 0 0 0 #4C3024,
        0 -2px 0 0 #4C3024,
        0 2px 0 0 #4C3024;
}

.DrawFunctionsTitle {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.DrawFunctions {
    margin-left: 10px;
}

.CraftInput {
    width: calc(100% - 8px);
    padding: 10px 4px;
    margin: 0px auto;
    display: block;
    box-shadow: -2px 0 0 0 #4C3024,
        2px 0 0 0 #4C3024,
        0 -2px 0 0 #4C3024,
        0 2px 0 0 #4C3024;
    font-family: fac;
    border: none;
    background: #FFF0D2;
}

.GenerateButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-image: url("../../assets/ui/Button.png") 12 fill;
    image-rendering: pixelated;
    border-width: 8px;
    padding: 6px 4px;
    text-align: center;
    color: #332018;
    cursor: pointer;
    width: 200px;
    margin-top: 20px;
}

.Strengthen {}

.StrengthenSelect {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.StrengthenTitle {
    text-align: center;
    font-size: 16px;
    margin: 10px 0px;
}

.StrengthenSelectProp p,
.StrengthenSelectMaterial p {
    text-align: center;
    width: 96px;
}

.StrengthenSelectPropItem,
.StrengthenSelectMaterialItem {
    position: relative;
    width: 96px;
    height: 96px;
    border-image: url("../../assets/ui/CraftSlot.png") 1 1 1 1 fill;
    border-style: solid;
    border-width: 2px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.StrengthenSelectAdd {
    width: 24px;
    height: 24px;
    margin-top: 20px;
}

.StrengthenButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-image: url("../../assets/ui/Button.png") 12 fill;
    image-rendering: pixelated;
    border-width: 8px;
    padding: 6px 4px;
    text-align: center;
    color: #332018;
    cursor: pointer;
    width: 200px;
    margin: 0px auto;
    margin-top: 20px;
}

.CraftProps {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.CraftProps li {
    cursor: pointer;
    position: relative;
    width: 48px;
    height: 48px;
    border-image: url("../../assets/ui/MyBagSlot.png") 6 6 6 6 fill;
    border-style: solid;
    border-width: 12px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.CraftPropsPopoverItem {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
}

.craftMaterials {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.craftMaterials li {
    cursor: pointer;
    position: relative;
    width: 48px;
    height: 48px;
    border-image: url("../../assets/ui/MyBagSlot.png") 6 6 6 6 fill;
    border-style: solid;
    border-width: 12px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.MaterialItemAmount {
    position: absolute;
    background: #000;
    right: -8px;
    bottom: -8px;
    color: #fff;
    font-size: 12px;
    padding: 0px 2px;
}
#SelectItem{
    border-image: url("../../assets/ui/BuildBarItemSelect.png") 12 12 12 12 fill;
}