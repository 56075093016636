.Community a{
    color: #99603F;
 
    margin-right: 10px;
}
.Community a:hover{
    text-decoration: underline;
}
.Community p{
    font-size: 14px;
    margin-bottom: 20px;

}