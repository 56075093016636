.SpellLand{
    position: relative;
    width: 352px;
    height: 288px;
}
.SpellLand_Animate1{
    left: 178px;
    top:120px;
    position: absolute;
}
.SpellLand_Animate2{
    left: 144px;
    top:120px;
    position: absolute;
}

.SpellLand_Animate3{
    left: 160px;
    top: 80px;
    width: 16px;
    height: 32px;
    position: absolute;
}
.SpellLand_Animate4{
    left: 144px;
    top: 32px;
    width: 48px;
    height: 40px;
    position: absolute;
}
.SchoolLandWitch{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 144px;
    position: absolute;
    bottom: 60px;
}