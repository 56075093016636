.SandLand{

    position: relative;
}
.SandLandCraft{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    right: 52px;
    position: absolute;
    bottom: 150px;
}
