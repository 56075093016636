.MarketLand{
    width: 336px;
    height: 224px;
    position: relative;
}
.MarketLandWitch{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    right: 90px;
    position: absolute;
    bottom: 70px;
}
