.SnowLand{
    position: relative;
}
.SnowLandPrincess{
    cursor: pointer;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom:40px;
    left: 206px;
}
.SnowLandGhost{
    cursor: pointer;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top:170px;
    right: 40px;
}

.SnowLandGhost{
    cursor: pointer;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top:120px;
    right: 30px;
}
.SnowLandSkeleton{
    cursor: pointer;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top:170px;
    left: 40px;
}
.SnowLandFire{
    top:75px;
    left: 228px;
    cursor: pointer;
    position: absolute;
}