.MyBagProps{
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
}
.MyBagProps li{
    position: relative;
    width: 48px;
    height: 48px;
    border-image: url("../../assets/ui/MyBagSlot.png") 6 6 6 6 fill;
    border-style: solid;
    border-width: 12px;
    margin-right: 10px;
    margin-bottom: 10px;
}
.MyBagItemAmount{
    position: absolute;
    background: #000;
    right: -8px;
    bottom: -8px;
    color: #fff;
    font-size: 12px;
    padding: 0px 2px;
}

.MyBagUseProp{
    cursor: pointer;
    border-style: solid;
    border-image: url("../../assets/ui/Tab.png")  12 fill;
    image-rendering: pixelated;
    border-width: 8px;
    text-align: center;
    color: #332018;
    cursor: pointer;
    height: 18px;
    line-height: 18px;
}
.MyBagGold{
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 2px solid #DE9F47;
    margin: 10px 0px;
}
.MyBagGoldTitle{
    font-size: 16px;
    align-items: center;
    display: flex;
}
.MyCrafts{
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
}
.MyCrafts li{
    position: relative;
    width: 48px;
    height: 48px;
    border-image: url("../../assets/ui/MyBagSlot.png") 6 6 6 6 fill;
    border-style: solid;
    border-width: 12px;
    margin-right: 10px;
    margin-bottom: 10px;
}
.MyCraftsPopoverItem{
    /* font-size: 12px; */
    display: flex;
    justify-content:space-between;
}

.MyCraftEquipment{
    position: absolute;
    background: #000;
    right: -8px;
    bottom: -8px;
    color: #fff;
    font-size: 12px;
    padding: 0px 2px;
    width: 60px;
    text-align: center;
}