.Battle{
    width: 1000px;
    height: 600px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: url("../../assets/BattleBackground.png");
    background-size: cover;
    image-rendering: pixelated;
    color: #fff;
}
.BattlePlayer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
}
.BattleMonster{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
}
.BattleSpellEffect{
    width: 120px;
    position: absolute;
    top: 10px;
    z-index: 1;
}
.BattleSpells{
    display: flex;
    flex-wrap: wrap;
}
.BattleSpells li{
    cursor: pointer;
}
.BattleLeave{
    cursor: pointer;
    width: 132px;
    height: 46px;
    text-align: center;
    background: url("../../assets/ui/BattleButton.png");
    background-size: cover;
    text-align: center;
    line-height: 46px;
    margin-top: 20px;
}

.BattleStart{
   cursor: pointer;
    width: 132px;
    height: 46px;
    text-align: center;
    background: url("../../assets/ui/BattleButton.png");
    background-size: cover;
    text-align: center;
    line-height: 46px;
    margin-top: 20px;
}

.BattleReward{
    display: flex;
    flex-wrap: wrap;
}
.BattleReward li{
    position: relative;
}
.BattleRewardAmount{
    bottom: 6px;
    right: 0px;
    font-size: 12px;
    position: absolute;
    background: #000;
    color: #fff;
    padding: 0px 2px;
}
.BattelSpellsTitle{
    margin-top: 30px;
}
.BattleRewardsTittle{
    margin-top: 30px;
}
.BattlePlayerImage{

}
.BattlePlayerState,.BattleMonsterState{
    margin-top: 20px;
}

.battleVictory,.battleDefeat{
    font-weight: bold;
    width: 100px;
    text-align: center;
    margin: 0px auto;
    font-size: 24px;
}