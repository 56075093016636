.PlayerAvatar {
    height: 78px;
}

.Player {

    display: flex;
    border-style: solid;
    border-image: url("../../assets/ui/PlayerBg.png") 6 6 6 6 fill;
    image-rendering: pixelated;
    border-width: 12px;
    font-size: 14px;
    margin-left: 10px;
    padding: 6px
}

.PlayerData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
}

.PlayerHP,
.PlayerMP {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    align-items: center;
}

.PlayerATKAndDEF {
    display: flex;
    justify-content: space-between;
}

.PlayerATK,
.PlayerDEF {
    display: flex;
    align-items: center;
}

.PlayerDEF {
    margin-left: 10px;
}

.PlayerDEFTitle,
.PlayerATKTitle {
    height: 24px;
}

.PlayerDEFValue,
.PlayerATKValue {
    height: 24px;
    line-height: 24px;

}

.PlayerHPValue {
    background: #FA3333;
    text-align: center;
    font-size: 12px;
    height: 12px;

}

.PlayerMPValue {
    background: #024EA5;
    text-align: center;
    font-size: 12px;
    height: 12px;
}

.PlayerValueBg {
    overflow: hidden;
    height: 12px;
    width: 100px;
    box-shadow: -2px 0 0 0 #000, 2px 0 0 0 #000, 0 -2px 0 0 #000, 0 2px 0 0 #000;
    margin-left: 10px;
}