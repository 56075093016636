.Market{
    width: 100%;
}
.Market li{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: -2px 0 0 0 #E5B75B,
    2px 0 0 0 #E5B75B,
    0 -2px 0 0 #E5B75B,
    0 2px 0 0 #E5B75B;
    padding: 10px;
    margin: 20px auto 0px;
}
.MarketItemImageAndInfo{
    display: flex;
}
.MarketItemImage{
    width: 48px;
    height: 48px;
}
.MarketItemOperate{
    display: flex;
}


.MarketHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0px auto;
}
  
.tabs {
    display: flex;
    justify-content: center;
    flex-direction:row;
    padding: 4px;
    color: #916345;
  }
  
  .tabs input[type="radio"] {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  
  .tabs input[type="radio"]:checked + span {
    z-index: 1;

    color: #4C3024;
    border-style: solid;
    border-image: url("../../assets/ui/Tab.png")  8 fill;
    image-rendering: pixelated;
    border-width: 5px;
  }
  
  .tabs label span {
    display: block;
    cursor: pointer;
    padding: 4px 12px;
    position: relative;
    letter-spacing: .05em;
    text-align: center;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(255, 0, 0, 0);

    color: #916345;
  }

  .MarketCheckout{
    
  }
  .MarketItemName{
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
  }
  .MarketItemName:hover{
    text-decoration: underline;
  }
  .MarketItemInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    width: 200px;
  }
  .MarketItemOperate{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .MarketItemOperateAdd{
    border-style: solid;
    border-image: url("../../assets/ui/Tab.png")  12 fill;
    image-rendering: pixelated;
    border-width: 8px;
    text-align: center;
    color: #332018;
    cursor: pointer;
    width: 18px;
    height: 18px;
  }
  .MarketItemOperateInput{
    width: 100px;
    padding: 6px 4px;
    display: block;
    box-shadow: -3px 0 0 0 #FFF0D2,
        3px 0 0 0 #FFF0D2,
        0 -3px 0 0 #FFF0D2,
        0 3px 0 0 #FFF0D2;
    font-family: fac;
    border: none;
    background: #FFF0D2;
    margin-right: 10px;
    margin-left: 10px;
  }
  .MarketContainer{
    padding-top: 20px;
  }
  .MarketFooter{
    display: flex;
    justify-content: space-between;
    width: 100%;
    bottom: 0px;
    border-top: 1px solid rgba(5, 5, 5, 0.06);
    align-items: center;
    padding-top: 10px;
  }
  .MarketCheckout{
    border-style: solid;
    border-image: url("../../assets/ui/Button.png")  12 fill;
    image-rendering: pixelated;
    border-width: 8px;
    padding: 6px 4px;
    text-align: center;
    color: #332018;
    cursor: pointer;
    width: 80px;
    margin-right: 10px;
  }
  .MarketTotal{
    margin-left: 10px;
  }
.MarketTotalCoin{
  font-size: 16px;
  font-weight: bold;
}
.MarketItemPrice,.marketBalance{
  display: flex;
  align-items: center;
}