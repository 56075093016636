.MyWalletTitle{
    font-weight: bold;
    margin-top: 20px;
}
.MyWalletValue{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.MyWalletCopy{
    border-style: solid;
    border-image: url("../../assets/ui/Button.png")  12 fill;
    image-rendering: pixelated;
    border-width: 8px;
    text-align: center;
    color: #332018;
    cursor: pointer;
    width: 56px;
}
.MyWalletCopy:active{
    color: #fff;
    border-image: url("../../assets/ui/ButtonActive.png")  12 fill;
}
.MyWalletRemove{
    border-style: solid;
    border-image: url("../../assets/ui/ButtonError.png")  12 fill;
    image-rendering: pixelated;
    border-width: 8px;
    text-align: center;
    color: #332018;
    cursor: pointer;
    width: 56px; 

}