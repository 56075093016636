.spells{

}
.spells li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 96%;
    margin: 20px auto 0px;
}
.spellImageNameInfo{
    display: flex;
    align-items: center; 
}
.spellImageName{
    display: flex;
    align-items: center; 
    width: 300px;
    cursor: pointer;
}
.spellImageName:hover{
    text-decoration: underline;
}
.spellImage{
    margin-right: 10px;
}
.spellPropsBundle{
    display: flex;
    align-items: center; 
}
.spellPropsBundleItem{
    display: flex;
    align-items: center; 
    margin-right: 10px;
}
.spellHave{
    width: 88px;
    text-align: center;
}
.spellGenerate{
    border-style: solid;
    border-image: url("../../assets/ui/Button.png")  12 fill;
    image-rendering: pixelated;
    border-width: 8px;
    text-align: center;
    color: #332018;
    cursor: pointer;
    width: 72px;
    padding: 4px 0px;
}